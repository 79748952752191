import axios from 'axios';
import { api, email } from "config"

const instance = axios.create({
    baseURL: api.API_URL,
    headers: { 
        'X-API-KEY': api.API_KEY,
        "X-USER-EMAIL": email
    }
});

export default instance;