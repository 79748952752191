import { navigate } from "gatsby"
import moment from "moment"
import * as actionTypes from "store/actions/types"
import { updateBoardFilters, fetchJobs } from "store/actions/jobs"
import axios from "utils/axios"
import { removeOccurrences } from "utils/"
import { api } from "config"
import axiosCatch from "utils/axiosCatch"
import { updateCurrentTab } from "./jobs"


import sectors from "config/refs/sectors.json"
import schools  from "config/refs/schools.json"
import geographies  from "config/refs/regions_france.json"


const sectorsLabels = sectors.map(e => e.label);
const schoolsLabels = schools.map(e => e.label);
const geographiesLabels = geographies.map(e => e.label);

const tagging = {}
const getTaggingPromise = (text, labels, type, tagger) =>  axios.post("/text/tagging", {
  "algorithm_key": tagger,
  "texts": [text],
  "labels": labels,
  "top_n": 1
}).then(res => {
  tagging[type] = res?.data?.data[0]?.tags[0] || null
  return tagging
}).catch(e => {
  tagging[`${type}`] = null
  return tagging
})

const getTaggingProfilePromise = (key, source_key, type, tagger) =>  axios.get(`/profile/tagging?algorithm_key=${tagger}&key=${key}&source_key=${source_key}&top_n=1`).then(res => {
  tagging[type] = res?.data?.data?.tags[0] || null
  return tagging
}).catch(e => {
  tagging[`${type}`] = null
  return tagging
})

export const showConfirmationModal = (value) => {
  return {
    type: actionTypes.SHOW_CONFIRMATION_MODAL,
    value,
  }
}

export const showWelcomeModal = (value) => {
  return {
    type: actionTypes.SHOW_WELCOME_MODAL,
    value,
  }
}

export const getAskingRequest = () => {
  return {
    type: actionTypes.GET_ASKING_REQUEST,
  }
}
export const getAskingSuccess = (asking) => {
  return {
    type: actionTypes.GET_ASKING_SUCCESS,
    asking
  }
}
export const getAskingFail = () => {
  return {
    type: actionTypes.GET_ASKING_FAIL,
  }
}


export const postTaggingRequest = () => {
  return {
    type: actionTypes.POST_TAGGING_REQUEST,
  }
}
export const postTaggingSuccess = (tagging) => {
  return {
    type: actionTypes.POST_TAGGING_SUCCESS,
    tagging
  }
}
export const postTaggingFail = () => {
  return {
    type: actionTypes.POST_TAGGING_FAIL,
  }
}

export const addProfileRequest = () => {
  return {
    type: actionTypes.ADD_PROFILE_REQUEST,
  }
}

export const addProfileSuccess = (profile, parsing, file, callBackUrl) => {
  return {
    type: actionTypes.ADD_PROFILE_SUCCESS,
    profile,
    parsing,
    file,
    callBackUrl
  }
}

export const addProfileFail = () => {
  return {
    type: actionTypes.ADD_PROFILE_FAIL,
  }
}

export const putProfileRequest = () => {
  return {
    type: actionTypes.PUT_PROFILE_REQUEST,
  }
}

export const putProfileSuccess = profile => {
  return {
    type: actionTypes.PUT_PROFILE_SUCCESS,
    profile,
  }
}

export const putProfileFail = () => {
  return {
    type: actionTypes.PUT_PROFILE_FAIL,
  }
}


export const getProfileRequest = () => {
  return {
    type: actionTypes.GET_PROFILE_REQUEST,
  }
}

export const getProfileSuccess = (profile, file) => {
  return {
    type: actionTypes.GET_PROFILE_SUCCESS,
    profile,
    file,
  }
}

export const getProfileFail = () => {
  return {
    type: actionTypes.GET_PROFILE_FAIL,
  }
}


export const addProfileStoringNoCvRequest = () => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_NOCV_REQUEST,
  }
}


export const addProfileStoringNoCvSuccess = () => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_NOCV_SUCCESS,
  }
}

export const addProfileStoringNoCvFail = () => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_NOCV_FAIL,
  }
}


export const addProfileStoringRequest = () => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_REQUEST,
  }
}


export const addProfileStoringSuccess = (profile, file) => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_SUCCESS,
    profile,
    file,
  }
}

export const addProfileStoringFail = () => {
  return {
    type: actionTypes.ADD_PROFILE_STORING_FAIL,
  }
}

export const getProfileStoringRequest = () => {
  return {
    type: actionTypes.GET_PROFILE_STORING_REQUEST,
  }
}

export const getProfileStoringSuccess = (profile, file) => {
  return {
    type: actionTypes.GET_PROFILE_STORING_SUCCESS,
    profile,
    file,
  }
}

export const getProfileStoringFail = () => {
  return {
    type: actionTypes.GET_PROFILE_STORING_FAIL,
  }
}

export const removeProfileRequest = () => {
  return {
    type: actionTypes.REMOVE_PROFILE,
  }
}



export const removeProfile = () => {
  return dispatch => {
    localStorage.removeItem("profile")
    localStorage.removeItem("parsing")
    localStorage.removeItem("profileStoring")
    localStorage.removeItem("file")
    const boardFilters = {
      searching: {
        skills: { enabled: [], disabled: [] },
        languages: { enabled: [], disabled: [] },
        jobs: [],
        locations: [],
        categories: [],
        types: [],
        orderBy: "desc",
        sortBy: "searching",
        useAgent: false,
        location_distance: 70,
        date_range_min: "1y",
      },
      created_at: {
        skills: { enabled: [], disabled: [] },
        languages: { enabled: [], disabled: [] },
        jobs: [],
        locations: [],
        categories: [],
        types: [],
        orderBy: "desc",
        sortBy: "created_at",
        useAgent: false,
        location_distance: 70,
        date_range_min: "1y",
      }
    }
    // continue here
    localStorage.setItem('boardFilters', JSON.stringify(boardFilters))
    dispatch(updateBoardFilters(boardFilters))
    dispatch(updateCurrentTab('created_at'))
    dispatch(fetchJobs({ boardFilters: boardFilters.created_at, page: 1, init: true }))
    dispatch(removeProfileRequest())
  }
}

export const getAsking = payload => {
  return dispatch => {
    dispatch(getAskingRequest())
    const questions = ["Ecrire un commentaire en 15 mots resumé sur ce profil"]
    axios
    .get(`profile/asking?key=${payload.key}&source_key=${payload.source_key}&questions=${questions}`)
    .then(res => {
      console.log('response asking', res)
      dispatch(getAskingSuccess(res.data.data[0]))

    }).catch(e => {
      dispatch(getAskingFail())
      console.log('error in question', e)
    })
  }
}


export const postTagging = payload => {
  return dispatch => {
    dispatch(postTaggingRequest())
    getTaggingProfilePromise(payload.key, payload.source_key, "experience", "tagger-safran-experience-level" )
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
    getTaggingProfilePromise(payload.key, payload.source_key, 'education', "tagger-safran-education-level")
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
    getTaggingProfilePromise(payload.key, payload.source_key, 'contract', "tagger-safran-contract-type")
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
    getTaggingPromise(payload.profile.text, sectorsLabels, 'sector', "tagger-hrflow-labels")
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
    getTaggingPromise(payload.profile.text, schoolsLabels, 'school', "tagger-hrflow-labels")
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
    getTaggingPromise( payload?.profile?.info?.location?.text + ', ' + 
      payload?.profile?.experiences?.map(exp => exp?.location.text).join(', ') + ', ' +  
      payload?.profile?.educations?.map(edu => edu?.location?.text).join(', ') + 'paris, normandie' , geographiesLabels, 'geography', "tagger-hrflow-labels")
    .then(function(res) {
      dispatch(postTaggingSuccess(res))
    }).catch(e => {
      dispatch(postTaggingFail(e))
    });
  
  /*   .then(function(values) {
      dispatch(postTaggingSuccess(values[0]))
    }).catch(e => {
      //dispatch(addProfileSuccess(payload.profileData.profile, payload.profileData.parsing, payload.profileData.file))
      dispatch(postTaggingFail(e))
    }); */
  }
}


export const addProfile = payload => {
  return dispatch => {
    dispatch(addProfileRequest())
    const fd = new FormData()
    fd.append("file", payload.file)
    fd.append("source_key", api.SOURCE_KEY)
    fd.append("sync_parsing", 1)
    fd.append("debugger", 1)
    fd.append(
      "consent_algorithmic",
      JSON.stringify({
        owner: {
          parsing: true,
          revealing: false,
          embedding: false,
          searching: true,
          scoring: true,
          reasoning: true,
        },
        controller: {
          parsing: true,
          revealing: false,
          embedding: false,
          searching: false,
          scoring: false,
          reasoning: false,
        },
      })
    )
    axios
      .post("profile/parsing/file", fd)
      .then(res => {
        const fileInfo = JSON.stringify({
          fileName: payload.file.name,
          fileSize: payload.file.size,
        })
        const callBackUrl = payload.jobKey ? `/job/?job_key=${payload.jobKey}&board_key=${payload.boardKey}` : '/jobs'
        dispatch(addProfileSuccess(res.data.data.profile, res.data.data.parsing, fileInfo, callBackUrl))
        dispatch(showConfirmationModal(true))
        /* dispatch(postTagging({key: res.data.data.profile.key, source_key: res.data.data.profile.source_key,  profile: res.data.data.profile, redirect: payload.redirect, profileData: {profile: res.data.data.profile, parsing: res.data.data.parsing, file: fileInfo }}))
        dispatch(getAsking({key: res.data.data.profile.key, source_key: res.data.data.profile.source_key })) */

        
        navigate("/confirmation")


      })
      .catch(err => {
        console.log("err", err)
        dispatch(addProfileFail(err))
      })
  }
}




const initProfile = (payload) => {
  return dispatch => {
    const profile = payload.profile
    localStorage.setItem("file", payload.fileInfo)
    localStorage.setItem("profile", JSON.stringify(profile))
    let experiences = []
    const words = profile.skills
      .map(item => item.name)
      .concat(profile.certifications.map(item => item.name))
    profile.experiences.forEach(experience => {
      experience.title &&
        experiences.push({ text: experience.title, checked: true })
      experience.skills.forEach(skill => {
        words.push(skill.name)
      })
      experience.certifications.forEach(certification => {
        words.push(certification.name)
      })
    })
    experiences = removeOccurrences(experiences)
    if (experiences.length) {
      experiences[0].checked = true
    }
  
    let locations = []
    if (
      profile.info.location.text &&
      profile.info.location.lat &&
      profile.info.location.lng
    ) {
      locations.push({
        text: profile.info.location.text,
        lat: profile.info.location.lat,
        lng: profile.info.location.lng,
      })
    }
    profile.experiences.forEach(experience => {
        (experience.location.text) &&
        experience.location.lat &&
        experience.location.lng &&
        locations.push({
          text: experience.location.text,
          lat: experience.location.lat,
          lng: experience.location.lng,
          checked: false,
        })
    })
    locations = removeOccurrences(locations)
    if (locations.length) {
      locations[0].checked = false
    }
    const boardFilters = {
      scoring: {
        skills: { enabled: [], disabled: words },
        languages: {
          enabled: [],
          disabled: profile.languages.map(item => item.name),
        },
        jobs: experiences.map(exp => ({text: exp.text, checked: false})),
        locations,
        categories: [],
        types: [],
        orderBy: "desc",
        sortBy: "scoring",
        useAgent: true,
        location_distance: 70,
        date_range_min: "1y",
      },
      searching: {
        skills: { enabled: words, disabled: [] },
        languages: {
          enabled: [],
          disabled: profile.languages.map(item => item.name),
        },
        jobs: experiences,
        locations,
        categories: [],
        types: [],
        orderBy: "desc",
        sortBy: "searching",
        useAgent: true,
        location_distance: 70,
        date_range_min: "1y",
      },
      created_at: {
        skills: { enabled: [], disabled: [] },
        languages: {
          enabled: [],
          disabled: [],
        },
        jobs: [],
        locations,
        categories: [],
        types: [],
        orderBy: "desc",
        sortBy: "created_at",
        useAgent: true,
        location_distance: 70,
        date_range_min: "1y",
      }
    }
  
    localStorage.setItem('boardFilters', JSON.stringify(boardFilters))
    dispatch(updateBoardFilters(boardFilters))
    dispatch(updateCurrentTab('scoring'))
    if (!payload.details && !payload.redirect) {
      dispatch(fetchJobs({ boardFilters: boardFilters.scoring, page: 1, init: true }))
    }
  }
}

export const addProfileStoring = payload => {
  return dispatch => {
    dispatch(addProfileStoringRequest())
    const newProfile = { ...payload.profile, source_key: api.SOURCE_KEY_STORING, reference: payload.reference }
    newProfile.experiences.map(exp => {
      exp.date_end = { iso8601: moment(exp.date_end).toISOString() }
      exp.date_start = { iso8601: moment(exp.date_start).toISOString() }
    })

    newProfile.educations.map(edu => {
      edu.date_end = { iso8601: moment(edu.date_end).toISOString() }
      edu.date_start = { iso8601: moment(edu.date_start).toISOString() }
    })
    axios
      .post("storing/profile", JSON.stringify(newProfile))
      .then(res => {
        const profile = res.data.data
        const parsing = payload.parsing
        dispatch(addProfileSuccess(profile, parsing, payload.fileInfo, ""))
        dispatch(initProfile({...payload, profile: profile, parsing: parsing}))
      
        // localStorage.setItem("parsing", JSON.stringify(payload.parsing))
        
 
        dispatch(showConfirmationModal(false))
      
        if (payload.redirect) {
          if(payload.token) {
            navigate(`/jobs`)
          } else {
            navigate("/jobs")
          }
        }
      })
      .catch(err => {
        console.log("err", err)
        dispatch(addProfileStoringFail(err))
      })
  }
}


export const addProfileStoringNoCv = payload => {
  return dispatch => {
    dispatch(addProfileStoringNoCvRequest())
    let BOARD_KEY = "50789e2cb6863cea495306c31910c059658aaf4b";
    let JOB_KEY = "349cc062a8256ac60fdeacaebab2b604e7548ba3";
    const newProfile = { ...payload.profile, source_key:  api.SOURCE_KEY_STORING_NOCV , reference: payload.reference }
    axios
      .post("storing/profile", JSON.stringify(newProfile))
      .then(res => {
        dispatch(addProfileStoringNoCvSuccess())
        axios
        .post("tracking", {
          action: 'apply',
          role: 'candidate',
          source_key: api.SOURCE_KEY_STORING_NOCV,
          job_key: JOB_KEY,
          board_key: BOARD_KEY,
          profile_key: res.data.data.key,
          author_email: payload.reference,
        })
      })
      .catch(err => {
        console.log("err", err)
        try {
          dispatch(addProfileStoringNoCvFail(err))
          axios
            .put("storing/profile", JSON.stringify(newProfile))
            .then(res => {
              dispatch(addProfileStoringNoCvSuccess())
              axios
              .post("tracking", {
                action: 'apply',
                role: 'candidate',
                source_key: api.SOURCE_KEY_STORING_NOCV,
                job_key: JOB_KEY,
                board_key: BOARD_KEY,
                profile_key: res.data.data.key,
                author_email: payload.reference,
              })
            })
        } catch (error) {
          dispatch(addProfileStoringNoCvFail(error))
        }
      })
  }
}

export const putProfile = payload => {
  return dispatch => {
    dispatch(putProfileRequest())
    const newProfile = { ...payload.profile, source_key: api.SOURCE_KEY_STORING }
    newProfile.attachments.map(att => {
      att.url = att.public_url
    })

    newProfile.experiences.map(exp => {
      exp.date_end = { iso8601: moment(exp.date_end).toISOString() }
      exp.date_start = { iso8601: moment(exp.date_start).toISOString() }
    })

    newProfile.educations.map(edu => {
      edu.date_end = { iso8601: moment(edu.date_end).toISOString() }
      edu.date_start = { iso8601: moment(edu.date_start).toISOString() }
    })
    let tag = {}
    if (payload.action === 'view') {
      tag = {
        name: "view_boardKey_jobKey",
        value: `${payload.boardKey}_${payload.jobKey}`,
      }
    }   
    if (payload.action === 'apply') {
      tag = {
        name: "application_boardKey_jobKey",
        value: `${payload.boardKey}_${payload.jobKey}`,
      }
    }
    
    if(payload.jobKey) {
      if (newProfile.tags && newProfile.tags.length > 0) {
        if(tag.hasOwnProperty('name')) {
          newProfile.tags = newProfile.tags.filter(tag => !(tag.name === "view_boardKey_jobKey" && tag.value === `${payload.boardKey}_${payload.jobKey}`))
          newProfile.tags.push(tag)
        }
      } else {
        if(tag.hasOwnProperty('name')) {
          newProfile.tags = [tag]
        }
      }
    }

    if(payload.action === 'apply') {
      axios
      .post("tracking", {
        action: 'apply',
        role: 'candidate',
        source_key: api.SOURCE_KEY_STORING,
        job_key: payload.jobKey,
        board_key: payload.boardKey,
        profile_key: newProfile.key,
        author_email: newProfile.info.email,
      })
      .then(res => {
        dispatch(putProfileSuccess(newProfile))
        localStorage.setItem("profile", JSON.stringify(newProfile))
      })
      .catch(err => {
        console.log("err", err)
        dispatch(putProfileFail(err))
      })

      /* const dataJson = {
        source_key:  api.SOURCE_KEY_STORING,
        job_key: payload.jobKey,
        board_key: payload.boardKey,
        profile_key: newProfile.key,
        author_email: newProfile.info.email,
      }
      axiosCatch.post('', dataJson).then(res => console.log('resposne catch', res)) */

      const fd = new FormData();
      fd.append('source_key', api.SOURCE_KEY_STORING);
      fd.append('job_key', payload.jobKey);
      fd.append('job_reference', payload.jobReference);
      fd.append('board_key', payload.boardKey);
      fd.append('job_reference', payload.jobReference);
      fd.append('profile_key', newProfile.key);
      fd.append('author_email', newProfile.info.email);
      
      /* axiosCatch.post('', fd).then(res => console.log('resposne catch', res)) */


    } else {
      axios
      .put("storing/profile", JSON.stringify(newProfile))
      .then(res => {
        const profile = res.data.data
        dispatch(putProfileSuccess(profile))
        console.log('here')
        navigate(payload.callBackUrl) // TODO: redirect to the callback page
        dispatch(showWelcomeModal(false))
        localStorage.setItem("profile", JSON.stringify(profile))
        if(payload.action === 'init') {
          const fileInfo = JSON.stringify({
            fileName: profile.attachments[0].file_name+profile.attachments[0].extension,
            fileSize:  profile.attachments[0].size,
          })
          dispatch( initProfile({
            profile: profile,
            fileInfo, 
            redirect: payload.redirect,
            details: payload.details,
            token: payload.token,
            parsing: profile
          }))

        
        }
      })
      .catch(err => {
        console.log("err", err)
        dispatch(putProfileFail(err))
      })
    }
  }
}

export const getProfile = payload => {
  return (dispatch, getState) => {
    dispatch(getProfileRequest())
    const profileKey = payload.profileKey
    let url = ""
    if(payload.reference){
      url = `storing/profile?&reference=${payload.reference}&source_key=${api.SOURCE_KEY_STORING}`
    } else {
      url = `storing/profile?&key=${profileKey}&source_key=${api.SOURCE_KEY}`
    }
    axios.get(url)
      .then(res => {
        const fileInfo = JSON.stringify({
          fileName: res.data.data.attachments[0].file_name+res.data.data.attachments[0].extension,
          fileSize:  res.data.data.attachments[0].size,
        })

        let profile = res.data.data
        if(payload.action === 'deduplication') {
          profile.info.email = payload.profile.info.email
          profile.info.first_name = payload.profile.info.first_name
          profile.info.last_name = payload.profile.info.last_name
          profile.info.full_name = payload.profile.info.last_name + ' ' + payload.profile.info.first_name
          profile.tags = payload.profile.tags
          profile.metadatas = payload.profile.metadatas

        }
        if(payload.action == 'redirect') {
          dispatch(getProfileSuccess(profile, fileInfo))

          if(payload.token) {
            navigate(`/jobs?token=${payload.token}`)
          } else {
            navigate("/jobs")
          }
          dispatch(showConfirmationModal(true))

        } else {
          dispatch(initProfile({
            profile: profile,
            fileInfo, 
            redirect: payload.redirect,
            details: payload.details,
            token: payload.token,
            parsing: profile
          }))
  
          dispatch(showConfirmationModal(false))
        
          dispatch(getProfileSuccess(profile, fileInfo))
          dispatch(showWelcomeModal(true))
        }
   
      })
      .catch(err => {
        console.log("err", err)
        const profile = getState("")?.profile?.profile?.payload.payload || null
        const fileInfo = JSON.stringify({
          fileName: profile.attachments[0].file_name+profile.attachments[0].extension,
          fileSize:  profile.attachments[0].size,
        })
        dispatch(addProfileStoring({
          profile: payload.profile,
          reference: payload.reference,
          fileInfo, 
          redirect: payload.redirect,
          details: payload.details,
          token: payload.token,
          parsing: profile
        }))

        dispatch(addProfileFail(err))
      })

  }
}


export const getProfileStoring = payload => {
  return dispatch => {
    dispatch(getProfileStoringRequest())
    const sourceKey = payload.sourceKey
    const profileKey = payload.profileKey
    axios
      .get(`storing/profile?&key=${profileKey}&source_key=${sourceKey}`)
      .then(res => {
        const profile = res.data.data
        const fileInfo = JSON.stringify({
          fileName: "file.pdf",
          fileSize: "1111",
        })
        localStorage.setItem("file", fileInfo)
        localStorage.setItem("profile", JSON.stringify(profile))
        dispatch(getProfileStoringSuccess(profile, fileInfo))
      })
      .catch(err => {
        console.log("err", err)
        dispatch(getProfileStoringFail(err))
      })
  }
}


