import * as actionTypes from "store/actions/types"
import axios from "axios"
import { api, catalog, email } from "config"


import {
  buildQueryString,
  rangeDateToIsoDate,
  removeOccurrencesString,
} from "utils/"

const instance = axios.create({
  baseURL: api.API_URL,
  headers: {
    "X-API-KEY": api.API_KEY,
    "X-USER-EMAIL": email
  },
})

function makeRequestCreator() {
  var call
  return function(url) {
    if (call) {
      call.cancel()
    }
    call = axios.CancelToken.source()
    return instance.get(url, { cancelToken: call.token })
  }
}

var get = makeRequestCreator()

export const updateBoardFilters = boardFilters => {
  return {
    type: actionTypes.UPDATE_BOARD_FILTERS,
    boardFilters,
  }
}

export const updateCurrentTab = tab => {
  return {
    type: actionTypes.UPDATE_CURRENT_TAB,
    tab,
  }
}

export const initJobsCategoriesState = payload => {
  return {
    type: actionTypes.INIT_JOBS_CATEGORIES_STATE,
    payload,
  }
}

export const fetchJobsRequest = () => {
  return {
    type: actionTypes.FETCH_JOBS_REQUEST,
  }
}

export const fetchJobsSuccess = jobs => {
  return {
    type: actionTypes.FETCH_JOBS_SUCCESS,
    jobs,
  }
}

export const fetchJobsFail = () => {
  return {
    type: actionTypes.FETCH_JOBS_FAIL,
  }
}

export const fetchSimilarJobsRequest = () => {
  return {
    type: actionTypes.FETCH_SIMILAR_JOBS_REQUEST,
  }
}

export const fetchSimilarJobsSuccess = jobs => {
  return {
    type: actionTypes.FETCH_SIMILAR_JOBS_SUCCESS,
    jobs,
  }
}

export const fetchSimilarJobsFail = () => {
  return {
    type: actionTypes.FETCH_SIMILAR_JOBS_FAIL,
  }
}

export const fetchRecommandedJobsRequest = () => {
  return {
    type: actionTypes.FETCH_RECOMMANDED_JOBS_REQUEST,
  }
}

export const fetchRecommandedJobsSuccess = jobs => {
  return {
    type: actionTypes.FETCH_RECOMMANDED_JOBS_SUCCESS,
    jobs,
  }
}

export const fetchRecommandedJobsFail = () => {
  return {
    type: actionTypes.FETCH_RECOMMANDED_JOBS_FAIL,
  }
}


export const fetchJobsCategoryRequest = (category) => {
  return {
    type: actionTypes.FETCH_JOBS_CATEGORY_REQUEST,
    category
  }
}

export const fetchJobsCategorySuccess = (jobs, category) => {
  return {
    type: actionTypes.FETCH_JOBS_CATEGORY_SUCCESS,
    jobs,
    category,
  }
}

export const fetchJobsCategoryFail = () => {
  return {
    type: actionTypes.FETCH_JOBS_CATEGORY_FAIL,
  }
}

export const fetchJobRequest = () => {
  return {
    type: actionTypes.FETCH_JOB_REQUEST,
  }
}

export const fetchJobSuccess = job => {
  return {
    type: actionTypes.FETCH_JOB_SUCCESS,
    job,
  }
}

export const fetchJobFail = () => {
  return {
    type: actionTypes.FETCH_JOB_FAIL,
  }
}

export const postDocumentParsingRequest = () => {
  return {
    type: actionTypes.POST_DOCUMENT_PARSING_REQUEST,
  }
}

export const postDocumentParsingSuccess = documentParsing => {
  return {
    type: actionTypes.POST_DOCUMENT_PARSING_SUCCESS,
    documentParsing,
  }
}

export const postDocumentParsingFail = () => {
  return {
    type: actionTypes.POST_DOCUMENT_PARSING_FAIL,
  }
}

export const postDocumentRevealingRequest = () => {
  return {
    type: actionTypes.POST_DOCUMENT_REVEALING_REQUEST,
  }
}

export const postDocumentRevealingSuccess = documentRevealing => {
  return {
    type: actionTypes.POST_DOCUMENT_REVEALING_SUCCESS,
    documentRevealing,
  }
}

export const postDocumentRevealingFail = () => {
  return {
    type: actionTypes.POST_DOCUMENT_REVEALING_FAIL,
  }
}


export const fetchJobs = payload => {
  return (dispatch, getState) => {
    dispatch(fetchJobsRequest())
    dispatch(initJobsCategoriesState(null))
    let location = {}
    const profileKey = getState("")?.profile?.profile?.payload?.key || null
    const locationGeo = payload.boardFilters.locations.filter(
      location => location.checked === true
    )
    if (locationGeo.length > 0) {
      location = {
        lat: payload.boardFilters.locations.filter(
          location => location.checked === true
        )[0].lat,
        lng: payload.boardFilters.locations.filter(
          location => location.checked === true
        )[0].lng,
      }
    }

    const isSearchingTabShown =
      payload.boardFilters &&
      (payload.boardFilters.skills.enabled.length > 0 ||
        (payload.boardFilters.jobs.length > 0 &&
          payload.boardFilters.jobs.filter(j => j.checked === true).length > 0))
    if (payload.first && isSearchingTabShown && !profileKey) {
      payload.boardFilters.sortBy = "searching"
    }
    if (!isSearchingTabShown) {
      if (profileKey && payload.boardFilters.useAgent === true) {
        payload.boardFilters.sortBy = "scoring"
      } else {
        payload.boardFilters.sortBy = "created_at"
      }
    }
    const names = payload.boardFilters.jobs
      .filter(job => job.checked === true)
      .map(name => encodeURIComponent(name.text.replace(/\s&+/g, "")))
    const queryObject = {
      board_keys: api.BOARD_KEYS,
      agent_key: api.AGENT_KEY,
      // tags_included: [payload.boardFilters.types, payload.boardFilters.categories],
      tags_included: [
        payload.boardFilters.types,
        payload.init ? [] : payload.boardFilters.categories[0] === 'all' ? [] : payload.boardFilters.categories,
      ],
      names: names || [],
      limit: 70,
      page: payload.page || 1,
      sort_by: payload.boardFilters.sortBy || "created_at",
      order_by: payload.boardFilters.orderBy || "desc",
      location_distance: payload.boardFilters.location_distance || 70,
      location_geopoint: location,
      use_agent: payload.boardFilters.useAgent ? 1 : 0,
      profile_key: profileKey || null,
      source_key: api.SOURCE_KEY_STORING,
      text_keywords: payload.boardFilters.skills.enabled.map(skill =>
        encodeURIComponent(skill)
      ),
      totalPage: 0,
      status: true,
      date_range_min: rangeDateToIsoDate(payload.boardFilters.date_range_min),
    }
    dispatch(updateBoardFilters(payload.boardFilters))
    const url = buildQueryString(
      `jobs/${
        payload.boardFilters.useAgent && profileKey ? "scoring" : "searching"
      }`,
      queryObject
    )
    get(url)
      .then(res => {
        const jobs = res.data.data.jobs
        if (
          res.data.data &&
          res.data.data.predictions &&
          res.data.data.predictions.length
        ) {
          const scores = res.data.data.predictions
          jobs.forEach((job, index) => {
            job.score = scores[index][1]
          })
        }
        const fetchedJobs = { jobs: jobs, meta: res.data.meta }
        let categories = []
        let boardFilters = payload.boardFilters

        if(catalog.key) {
          if (payload.boardFilters.categories[0] === "all"|| (payload.init === true && payload.boardFilters.categories.length === 0 )) {

            categories = fetchedJobs.jobs.map(
              job => job.tags.filter(tag => tag.name === catalog.key)[0]
            )
            categories = categories.filter(cat => cat)

            if(categories.length > 0) {
              categories = removeOccurrencesString(categories).splice(0, 4)
              boardFilters = { ...payload.boardFilters, categories }
              dispatch(updateBoardFilters(boardFilters))
            } else {
              categories = ["all"]
              boardFilters = { ...payload.boardFilters, categories }
              dispatch(updateBoardFilters(boardFilters))
            }
           
          } else {
            categories = payload.boardFilters.categories
          }
        } else {
          categories = ["all"]
          boardFilters = { ...payload.boardFilters, categories }
          dispatch(updateBoardFilters(boardFilters))
        }

        dispatch(fetchJobsSuccess(fetchedJobs))
        localStorage.setItem("jobs", JSON.stringify({ payload: fetchedJobs }))
      })
      .catch(function(thrown) {
        console.log('thrown fetchJobs', thrown)
        if (axios.isCancel(thrown)) {
        } else {
          dispatch(fetchJobsFail(thrown))
        }
      })
  }
}

export const fetchJobsCategory = payload => {
  return (dispatch, getState) => {
    if (payload.action && payload.action === "delete") {
      dispatch(updateBoardFilters(payload.boardFilters))
      dispatch(initJobsCategoriesState(1))
    } else {
      dispatch(fetchJobsCategoryRequest(payload.category.value))

      let location = {}
      const profileKey = getState("")?.profile?.profile?.payload?.key || null
      const locationGeo = payload.boardFilters.locations.filter(
        location => location.checked === true
      )
      if (locationGeo.length > 0) {
        location = {
          lat: payload.boardFilters.locations.filter(
            location => location.checked === true
          )[0].lat,
          lng: payload.boardFilters.locations.filter(
            location => location.checked === true
          )[0].lng,
        }
      }
      const names = payload.boardFilters.jobs
        .filter(job => job.checked === true)
        .map(name => encodeURIComponent(name.text.replace(/\s&+/g, "")))

      const isSearchingTabShown =
        payload.boardFilters &&
        (payload.boardFilters.skills.enabled.length > 0 ||
          (payload.boardFilters.jobs.length > 0 &&
            payload.boardFilters.jobs.filter(j => j.checked === true).length > 0))
      if (isSearchingTabShown && !profileKey) {
        payload.boardFilters.sortBy = "searching"
      }
      if (!isSearchingTabShown) {
        if (profileKey && payload.boardFilters.useAgent === true) {
          payload.boardFilters.sortBy = "scoring"
        } else {
          payload.boardFilters.sortBy = "created_at"
        }
      }
      const queryObject = {
        board_keys: api.BOARD_KEYS,
        agent_key: api.AGENT_KEY,
        tags_included: [
          payload.boardFilters.types,
          [{
            name: catalog.key,
            value: encodeURIComponent(payload.category.value)
          }]       
        ],
        names: names || [],
        limit: 10,
        page: payload.page || 1,
        sort_by: payload.boardFilters.sortBy || "created_at",
        order_by: payload.boardFilters.orderBy || "desc",
        location_distance: payload.boardFilters.location_distance || 70,
        location_geopoint: location,
        use_agent: payload.boardFilters.useAgent ? 1 : 0,
        profile_key: profileKey || null,
        source_key: api.SOURCE_KEY_STORING,
        text_keywords: payload.boardFilters.skills.enabled.map(skill =>
          encodeURIComponent(skill)
        ),
        totalPage: 0,
        status: true,
        date_range_min: rangeDateToIsoDate(payload.boardFilters.date_range_min),
      }
      dispatch(updateBoardFilters(payload.boardFilters))
      const url = buildQueryString(
        `jobs/${
          payload.boardFilters.useAgent && profileKey ? "scoring" : "searching"
        }`,
        queryObject
      )
      instance
        .get(url)
        .then(res => {
          const jobs = res.data.data.jobs
          if (
            res.data.data &&
            res.data.data.predictions &&
            res.data.data.predictions.length
          ) {
            const scores = res.data.data.predictions
            jobs.forEach((job, index) => {
              job.score = scores[index][1]
            })
          }
          const fetchedJobs = { jobs: jobs, meta: res.data.meta }
          dispatch(fetchJobsCategorySuccess(fetchedJobs, payload.category.value))
          localStorage.setItem("jobs", JSON.stringify({ payload: fetchedJobs }))
        })
        .catch(function(thrown) {
          if (axios.isCancel(thrown)) {
            console.log("First request canceled", thrown)
          } else {
            console.log("thrown", thrown)
            dispatch(fetchJobsCategoryFail(thrown))
          }
        })
    }
  }
}

export const postDocumentParsing = payload => {
  return dispatch => {
    dispatch(postDocumentParsingRequest())
    let data = {};
    const sections = payload.sections;
    if(sections.length === 1) {
       data = {text: sections[0].description }
    }
    if(sections.length > 1) {
      data = {text: sections[0].description  +'/n/n'+ sections[1].description}
    }


    const url = `document/parsing`
    instance
      .post(url, data)
      .then(res => {
        dispatch(postDocumentParsingSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(postDocumentParsingFail(err))
      })
  }
}

export const postDocumentRevealing = payload => {
  return dispatch => {
    dispatch(postDocumentRevealingRequest())
 /*    const description =
      payload.sections.filter(section => section.name === "description")[0]
        .description || "" */
        const description = payload.summary  || ''
    const data = { text: description }
    const url = `document/revealing`
    instance
      .post(url, data)
      .then(res => {
        const data = res.data.data.splice(0, 3)
        dispatch(postDocumentRevealingSuccess(data))
      })
      .catch(err => {
        dispatch(postDocumentRevealingFail(err))
      })
  }
}

export const fetchSimilarJobs = payload => {
  return (dispatch, getState) => {
    dispatch(fetchSimilarJobsRequest())
    const profileKey = getState("")?.profile?.profile?.payload?.key || null
    const boardFilters = JSON.parse(localStorage.getItem("boardFilters"))
    const currentTab = getState("")?.jobs?.currentTab || null
    let location = {}
    if(currentTab && boardFilters) {      
      const locationGeo = boardFilters[`${currentTab}`].locations.filter(
        location => location.checked === true
      )
      if (locationGeo.length > 0) {
        location = {
          lat: boardFilters[`${currentTab}`].locations.filter(
            location => location.checked === true
          )[0].lat,
          lng: boardFilters[`${currentTab}`].locations.filter(
            location => location.checked === true
          )[0].lng,
        }
      }
    }
    const queryObject = {
      board_keys: api.BOARD_KEYS,
      agent_key: api.AGENT_KEY,
      tags_included: [
        [], 
        [{ name: catalog.key, value:  payload.category}],
      ],
      names: [],
      limit: 11,
      page: 1,
      sort_by: "created_at",
      order_by: "desc",
      location_distance: boardFilters && boardFilters[`${currentTab}`]?.location_distance || 70,
      location_geopoint: location,
      use_agent: boardFilters && boardFilters.useAgent ? 1 : 0,
      profile_key: profileKey || null,
      source_key: api.SOURCE_KEY_STORING,
      text_keywords: boardFilters && boardFilters[`${currentTab}`]?.skills.enabled.map(skill =>
        encodeURIComponent(skill)
      ),
      totalPage: 0,
      status: true,
      // date_range_min: rangeDateToIsoDate(payload.boardFilters.date_range_min)
    }
    const url = buildQueryString(
      `jobs/${boardFilters?.useAgent && profileKey ? "scoring" : "searching"}`,
      queryObject
    )
    instance
      .get(url)
      .then(res => {
        const jobs = res.data.data.jobs.filter(job => job.key !== payload.key)
        if (
          res.data.data &&
          res.data.data.predictions &&
          res.data.data.predictions.length
        ) {
          const scores = res.data.data.predictions
          jobs.forEach((job, index) => {
            job.score = scores[index][1]
          })
        }
        const fetchedJobs = { jobs: jobs, meta: res.data.meta }
        dispatch(fetchSimilarJobsSuccess(fetchedJobs))
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log("First request canceled", thrown)
        } else {
          console.log("thrown", thrown)
          dispatch(fetchSimilarJobsFail(thrown))
        }
      })
  }
}

export const fetchRecommandedJobs = payload => {
  return (dispatch, getState) => {
    dispatch(fetchRecommandedJobsRequest())
    const profileKey = getState("")?.profile?.profile?.payload?.key || null
    const boardFilters = JSON.parse(localStorage.getItem("boardFilters"))
    const currentTab = getState("")?.jobs?.currentTab || null
    let location = {}
    if(currentTab && boardFilters) {      
      const locationGeo = boardFilters[`${currentTab}`].locations.filter(
        location => location.checked === true
      )
      if (locationGeo.length > 0) {
        location = {
          lat: boardFilters[`${currentTab}`].locations.filter(
            location => location.checked === true
          )[0].lat,
          lng: boardFilters[`${currentTab}`].locations.filter(
            location => location.checked === true
          )[0].lng,
        }
      }
    }
    const queryObject = {
      board_keys: api.BOARD_KEYS,
      agent_key: api.AGENT_KEY,
      tags_included: [[], []],
      names: [],
      limit: 11,
      page: 1,
      sort_by: boardFilters?.scoring?.useAgent && profileKey ? "scoring" : "created_at",
      order_by: "desc",
      location_distance: boardFilters && boardFilters[`${currentTab}`]?.location_distance || 70,
      location_geopoint: location,
      use_agent: boardFilters && boardFilters?.scoring?.useAgent ? 1 : 0,
      profile_key: profileKey || null,
      source_key: api.SOURCE_KEY_STORING,
      text_keywords: [],
      totalPage: 0,
      status: true,
    }
    
    const url = buildQueryString(
      `jobs/${boardFilters?.scoring?.useAgent && profileKey ? "scoring" : "searching"}`,
      queryObject
    )
    instance
      .get(url)
      .then(res => {
        const jobs = res.data.data.jobs.filter(job => job.key !== payload.key)
        if (
          res.data.data &&
          res.data.data.predictions &&
          res.data.data.predictions.length
        ) {
          const scores = res.data.data.predictions
          jobs.forEach((job, index) => {
            job.score = scores[index][1]
          })
        }
        const fetchedJobs = { jobs: jobs, meta: res.data.meta }
        dispatch(fetchRecommandedJobsSuccess(fetchedJobs))
      })
      .catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log("First request canceled", thrown)
        } else {
          console.log("thrown", thrown)
          dispatch(fetchSimilarJobsFail(thrown))
        }
      })
  }
}

export const fetchJob = payload => {
  return dispatch => {
    dispatch(fetchJobRequest())
    const queryObject = {
      board_key: payload.boardKey,
      key: payload.jobKey,
    }
    const url = buildQueryString(`job/indexing`, queryObject)
    instance
      .get(url)
      .then(res => {
        dispatch(fetchJobSuccess(res.data.data))
        dispatch(postDocumentParsing(res.data.data))
        dispatch(postDocumentRevealing(res.data.data))
        const job = res.data.data
        const category = job.tags?.filter(t => t.name === catalog.key)?.[0]
          ?.value
        dispatch(fetchSimilarJobs({ category, key: job.key }))
        dispatch(fetchRecommandedJobs({ key: job.key }))
      })
      .catch(err => {
        console.log("err", err)
        dispatch(fetchJobFail(err))
      })
  }
}